<style scoped lang="less">
  .menu-person {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
    .label {
      color: #FFF;
    }
    .chart {
      border-radius: 20px;
      background-color: #FFF;
      height: 40px;
      width: 40px;
    }
  }
</style>

<template>
  <div class="menu-person">
    <div class="item">
      <div class="label">基础信息</div>
      <div class="chart" ref="base"></div>
    </div>
    <div class="item">
      <div class="label">工作信息</div>
      <div class="chart" ref="work"></div>
    </div>
    <div class="item">
      <div class="label">身份证</div>
      <div class="chart" ref="idcard"></div>
    </div>
  </div>
</template>

<script>
import {
  fileRequest
} from '@/api'

import {
  dataAppleRequest
} from '../../../api'

import {
  getAge
} from '@/syslib/tools'

const echarts = require('echarts')

function getOptions (v) {
  return {
    tooltip: {
      show: false
    },
    legend: {
      show: false
    },
    title: {
      text: '{v|'+(v * 100).toFixed(0)+'}{u|%}',
      left: 'center',
      top: 'center',
      textStyle: {
        rich: {
          v: {
            color: '#868686',
            verticalAlign: 'bottom',
            fontSize: 12
          },
          u: {
            color: '#868686',
            verticalAlign: 'bottom',
            fontSize: 6
          }
        }
      }
    },
    series: [
      {
        name: 'main',
        type: 'pie',
        radius: ['75%', '100%'],
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: false
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: v, name: 'value', itemStyle: {color: '#D96C86'} },
          { value: 1 - v, name: 'empty', itemStyle: {color: '#EDE1E1'} },
        ]
      }
    ]
  }
}

export default {
  props: {
    workerId: { type: Number, default: null }
  },
  data () {
    return {
      allData: null,
      files: [],
      $baseChart: null,
      $workChart: null,
      $idCardChart: null
    }
  },
  computed: {
    sourceData () {
      return this.allData && this.allData.worker ? this.allData.worker.source : null
    },
    draftData () {
      return this.allData && this.allData.worker && this.allData.worker.draft ? this.allData.worker.draft.newDataInfo : null
    },
    completeness () {
      let worker = null
      if (this.allData && this.allData.worker) {
        worker = JSON.stringify(this.draftData || this.sourceData)
        worker = JSON.parse(worker)
        worker.maritalStatus = this.sourceData ? this.sourceData.maritalStatus : null
        if (worker.birth) {
          worker.age = getAge(worker.birth)
        }
      }

      let base = ['name', 'sex', 'clan', 'politicalStatus', 'idNo', 'birth', 'age', 'sf', 'education', 'hometown', 'phone', 'agreementCode', 'graduatedSchool']
      let work = ['code','orgName','zg','positionId','positionIds','zhuanzhi','type','jobTypeC','maritalStatus','correctionDate','entryDate','jobType','employmentForm','discipline','address','shuangjiantiao','email','jobTitle']

      return {
        base: !worker ? 0 : base.filter(key => worker[key]).length / base.length,
        work: !worker ? 0 : work.filter(key => worker[key]).length / work.length,
        idCard: this.files.length > 1 ? 1 : 0
      }
    }
  },
  watch: {
    workerId () {
      this.loadData()
    },
    completeness () {
      this.loadBaseChart()
      this.loadWorkChart()
      this.loadIdCardChart()
    }
  },
  methods: {
    loadBaseChart () {
      if (!this.$baseChart) {
        this.$baseChart = echarts.init(this.$refs.base)
      }
      this.$baseChart.setOption(getOptions(this.completeness.base), true)
    },
    loadWorkChart () {
      if (!this.$workChart) {
        this.$workChart = echarts.init(this.$refs.work)
      }
      this.$workChart.setOption(getOptions(this.completeness.work), true)
    },
    loadIdCardChart () {
      if (!this.$idCardChart) {
        this.$idCardChart = echarts.init(this.$refs.idcard)
      }
      this.$idCardChart.setOption(getOptions(this.completeness.idCard), true)
    },
    async loadData () {
      // 接口需要换
      if (this.workerId) {
        return
      }
      if (this.workerId) {
        try {
          this.files = await fileRequest.get({
            path: '/worker_idcard/' + this.workerId + '/'
          })
        } catch (error) {
          this.files = []
        }
        let datas = await dataAppleRequest.getDraft({ workerId: this.workerId })
        if (datas.length > 0) {
          this.allData = datas[0]
        } else {
          this.allData = null
          this.$notify({ type: 'danger', message: '数据不存在' })
        }
      } else {
        this.files = []
        this.allData = null
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
